import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '../Components/TextField';
import { login } from '../state/admin';

import logo from '../images/logo.png';

const Login = ({ actions, admin }) => {
  admin && navigate('/');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const onChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
    }
  };

  const onClickSubmit = (event) => {
    event.preventDefault();
    setError(null);
    if (!email || !password) {
      return;
    }
    actions.login(email, password).catch((e) => {
      setError(e);
    });
  };

  return (
    <div className="login_container">
      <form onSubmit={onClickSubmit} className="login_container__form">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{
            alignSelf: 'center',
          }}
        />
        <p className="login_container__header">{'Email Address'}</p>
        <TextField
          name={'email'}
          type={'email'}
          placeholder={'Enter your email address'}
          onChange={onChange}
          value={undefined}
        />
        <p className="login_container__header">{'Password'}</p>
        <TextField
          name={'password'}
          type={'password'}
          placeholder={'Enter your password'}
          onChange={onChange}
          value={undefined}
        />
        <button type="submit" className="login_container__submit">
          Submit
        </button>
        <a className="login_container__forgot-password" href="/forgot-password">
          Forgot password?
        </a>
      </form>
      {error && <p className="login_container__error">{error.toString()}</p>}
    </div>
  );
};

Login.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
};

const mapStateToProps = (state) => {
  return { admin: state.admin };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        login,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
